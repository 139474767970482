<template>
  <div v-if="!pdfType && videoId" class="rs-video">
    <WistiaVideoEmbed v-if="isWistia" :videoId="videoId" />

    <div v-else class="rs-video__responsive">
      <YoutubeVideoEmbed :videoId="videoId" />
    </div>
  </div>
</template>

<script>
import WistiaVideoEmbed from "@/components/WistiaVideoEmbed.vue";
import YoutubeVideoEmbed from "@/components/YoutubeVideoEmbed.vue";

export default {
  components: {
    WistiaVideoEmbed,
    YoutubeVideoEmbed,
  },
  props: {
    value: {
      type: String,
    },
    isEditing: {
      type: Boolean,
      required: false,
    },
    isMobile: {
      type: Boolean,
    },
    pdfType: {
      type: String,
    },
    advisor: {
      type: Object,
    },
  },
  data() {
    return {
      videoId: null,
      isWistia: false,
    };
  },
  mounted() {
    let videoUrl;
    if (this.advisor && this.advisor.videoUrl) {
      videoUrl = this.advisor.videoUrl;
    } else if (this.value) {
      videoUrl = this.value;
    } else {
      return;
    }

    this.videoId = this.getVideoId(videoUrl);
  },
  methods: {
    getVideoId(url) {
      // Extract the video ID from the URL

      let videoId;
      if (url.includes("wistia.com/medias/")) {
        // eg: https://impactpartner.wistia.com/medias/hvsktwpiou
        videoId = url.split("wistia.com/medias/")[1];
        this.isWistia = true;
      } else if (url.includes("wistia.com/embed/medias/")) {
        // eg: https://fast.wistia.com/embed/medias/hvsktwpiou.jsonp
        // eg: https://fast.wistia.com/embed/medias/hvsktwpiou
        videoId = url.split("wistia.com/embed/medias/")[1].split(".")[0];
        this.isWistia = true;
      } else if (url.includes("youtu.be")) {
        // If URL is of the form 'youtu.be', extract the video ID
        videoId = url.split("youtu.be/")[1];
        this.isWistia = false;
      } else {
        // If URL is of the form 'www.youtube.com', extract the video ID
        const urlParams = new URLSearchParams(new URL(url).search);
        videoId = urlParams.get("v");
        this.isWistia = false;
      }

      return videoId;
    },
  },
};
</script>

<style lang="scss" scoped>
.rs-video {
  &__responsive {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
